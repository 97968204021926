<template>
  <div class="home-TUIKit-main" style="height: 620px">


    <el-form class="border-self" :model="userInfo" style="width: 220px">

      <el-form-item label="当前进行中对话：">
        {{ userInfo.progressSingleCount + userInfo.progressGroupCount }}个
        <p>
          （私信{{ userInfo.progressSingleCount }}个，群聊{{ userInfo.progressGroupCount }}个）
        </p>
      </el-form-item>

      <el-form-item>
        <img v-if="userInfo.avatar" :src="userInfo.avatar" class="avatar">
      </el-form-item>

      <el-form-item label="uid：">
        {{ userInfo.uid }}
      </el-form-item>

      <el-form-item label="姓名：" prop="nickname">
        {{ userInfo.nickname }}
      </el-form-item>

      <el-form-item label="更新时间:">
        {{ userInfo.updateTime }}
      </el-form-item>

      <el-form-item label="上一次编辑人：">
        {{ userInfo.updatePersonName }}
      </el-form-item>

      <el-form-item label="性别：">
        {{ getLabel(genderList, userInfo.gender, 'value', 'label') }}
      </el-form-item>

      <el-form-item label="职业：" prop="occupation">
        {{ userInfo.occupation }}
      </el-form-item>

      <el-form-item label="状态：">
        {{ getLabel(virtualStatusList, userInfo.virtualStatus, 'value', 'label') }}
      </el-form-item>

      <el-form-item label="所属IP：">
        {{ getVirIpLabel(userInfo.virtualIp) }}
      </el-form-item>

    </el-form>

    <div :class="['home']" id="preloadedImages">
      <!-- 这里暂时只考虑PC端 -->
      <main class="home-main" :class="['home-main-open']">
        <div class="home-main-box">
          <div class="home-TUIKit">
            <div class="home-TUIKit-main">
              <div class="home-conversation">
                <TUISearch :class="['TUISearch']" :is-relation="false" />
                <TUIConversation @handleSwitchConversation="handleSwitchConversation" :class="['TUIConversation']">
                </TUIConversation>
              </div>
              <div class="home-chat">
                <!-- 聊天入口在这里，注意需要当前的 conversationID 才能打开 chat -->
                <TUIChat :class="['TUIChat']" :humanResponse="humanResponse" :userTags="userTags"
                  @leaveHumanResponse="leaveHumanResponse" @changeToHumanResponse="changeToHumanResponse">
                  <div class="container-default">
                  </div>
                </TUIChat>
              </div>
            </div>

          </div>
        </div>
      </main>
    </div>

  </div>
</template>

<script>
import { StoreName, TUIGlobal, TUIStore } from "@tencentcloud/chat-uikit-engine";
import { TUIChat, TUIConversation, TUISearch } from "@/TUIKit";
import { TUILogin } from "@tencentcloud/tui-core";

const sourceUserInfo = {
  uid: -1,
  avatar: '',
  nickname: '',
  gender: '',
  occupation: '',
  virtualStatus: '',
  virtualIp: '',
  updateTime: '',
  updatePersonName: '',
  progressSingleCount: 0,
  progressGroupCount: 0,
  autoReplyStatus: 0
}


export default {
  name: "ImManage",
  components: {
    TUISearch,
    TUIConversation,
    TUIChat,
  },
  props: {
    uid: {
      type: Number,
      required: true
    },
    ipList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  watch: {
    uid(nVal) {
      this.setUserInfo(nVal)
      this.getImUserIdAndLogin(nVal)
    }
  },
  created() {
    this.setUserInfo(this.uid)
    this.getImUserIdAndLogin(this.uid)
  },
  data() {
    return {
      humanResponse: false,
      userTags: {},
      currentModel: "conversation",
      isH5: TUIGlobal.getPlatform() === "h5",
      currentConversationID: "",
      userInfo: Object.assign(sourceUserInfo),
      genderList: [
        {
          value: 0,
          label: '保密'
        },
        {
          value: 1,
          label: '男'
        },
        {
          value: 2,
          label: '女'
        }
      ],
      virtualStatusList: [
        {
          value: 0,
          label: '正常'
        },
        {
          value: 1,
          label: '下线'
        },
        {
          value: 2,
          label: '死亡'
        }
      ],

    };
  },
  mounted() {
    TUIStore.watch(StoreName.CONV, {
      currentConversationID: (id) => {
        this.currentConversationID = id;
      },
    });
    // console.log('ipList', this.ipList)
  },
  methods: {
    getImUserIdAndLogin(userID) {
      this.$http({
        url: this.$http.adornUrl('/admin/virtual/getImUserId'),
        method: 'get',
        params: this.$http.adornParams({
          uid: userID,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.imLogin(data.result)
        } else {
          this.$message({
            message: data.msg,
            type: 'error'
          })
        }
      })
    },
    exportChatData() {
      const exportData = {
        count: 100,
        startTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
        endTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null
      }
      const paramJson = encodeURI(JSON.stringify(exportData).replace(/\+/g, "%2b"))
      window.open(
        this.$http.adornUrl('/admin/virtual/export') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`,
        "_blank"
      );
    },
    async imLogin(userID) {
      if (TUILogin.userID !== undefined && TUILogin.userID !== "") {
        await TUILogin.logout()
        TUIStore.update(StoreName.CONV, 'currentConversationID', "");
      }
      const appId = await this.getImAppId();
      const sign = await this.getImSign(userID);
      TUILogin.login({
        SDKAppID: appId,
        userID,
        // UserSig 是用户登录即时通信 IM 的密码，其本质是对 UserID 等信息加密后得到的密文。
        // 该方法仅适合本地跑通 Demo 和功能调试，详情请参见 https://cloud.tencent.com/document/product/269/32688
        userSig: sign,
        // 如果您需要发送图片、语音、视频、文件等富媒体消息，请设置为 true
        useUploadPlugin: false,
        // 本地审核可识别、处理不安全、不适宜的内容，为您的产品体验和业务安全保驾护航
        // 此功能为增值服务，请参考：https://cloud.tencent.com/document/product/269/79139
        // 如果您已购买内容审核服务，开启此功能请设置为 true
        useProfanityFilterPlugin: false,
        framework: 'vue2'
      }).then(() => {
        TUIStore.update(StoreName.CONV, 'newUserLogin', userID);
      });

    },
    async getImAppId() {
      let appId;
      await this.$http({
        url: this.$http.adornUrl(`/admin/virtual/getAppId`),
        method: 'get'
      }).then(({ data }) => {
        appId = Number(data.result)
      })
      return appId;
    },
    async getImSign(imUid) {
      let sign;
      await this.$http({
        url: this.$http.adornUrl(`/admin/virtual/imSignByImUid`),
        method: 'get',
        params: this.$http.adornParams({ imUid: imUid })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          sign = data.result
        } else {
          this.$message.error('获取im相关信息失败,' + data.msg)
        }
      })
      return sign;
    },

    setUserInfo(uid) {
      this.userInfo = Object.assign(sourceUserInfo)
      this.humanResponse = false
      this.$http({
        url: this.$http.adornUrl(`/admin/virtual/info`),
        method: 'get',
        params: this.$http.adornParams({ uid: uid })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.userInfo.uid = data.result.uid
          this.userInfo.avatar = data.result.ar.avatar
          this.userInfo.userName = data.result.ar.username
          this.userInfo.nickname = data.result.ar.nickname
          this.userInfo.gender = data.result.ar.gender
          this.userInfo.occupation = data.result.ar.occupation
          this.userInfo.virtualStatus = data.result.ar.virtualStatus
          this.userInfo.virtualIp = data.result.ar.virtualIpIds
          this.userInfo.updateTime = data.result.ar.updateTime
          this.userInfo.updatePersonName = data.result.ar.updatePersonName
          this.userInfo.autoReplyStatus = data.result.ar.autoReplyStatus
          // 1是人工回复
          // this.humanResponse = (this.userInfo.autoReplyStatus === 1)
        }
      })
      // 获取聊天统计
      this.$http({
        url: this.$http.adornUrl(`/admin/chatData/progressChatCount`),
        method: 'get',
        params: this.$http.adornParams({ uid: uid })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.userInfo.progressSingleCount = data.result.progressSingleCount
          this.userInfo.progressGroupCount = data.result.progressGroupCount
        }
      })
    },

    getLabel(list, id, value, label) {
      if (Array.isArray(list) && list.length != 0) {
        let temp = list.find(item => item[value] == id)
        return !temp ? id : temp[label]
      } else {
        return id
      }
    },
    getVirIpLabel(ipList) {
      // console.log('ipStr', ipList)
      let temp = []
      if (ipList && Array.isArray(ipList) && ipList.length != 0) {
        ipList.forEach(ip => {
          temp.push(this.getLabel(this.ipList, ip, 'id', 'description'))
        })
        return temp.join('、')
      }
      return ipList

    },

    // 修改回复状态
    changeToHumanResponse(isHumanResponse) {
      const conversationInfo = TUIStore.getConversationModel(this.currentConversationID)
      const type = conversationInfo.type;
      console.log(conversationInfo)
      const isGroup = (type === "GROUP")

      this.$http({
        url: this.$http.adornUrl('/admin/virtual/updateAutoReplyStatus'),
        method: 'post',
        data: this.$http.adornData({
          virtualUid: this.uid,
          objectId: isGroup ? conversationInfo.groupProfile.groupID : conversationInfo.userProfile.userID,
          type: isGroup ? 1 : 0,
          autoReplyStatus: (isHumanResponse === true ? 1 : 0)
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.handleSwitchConversation(this.currentConversationID)
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    leaveHumanResponse(leave) {
      // 离开说明是非人工回复
      this.changeToHumanResponse(!leave)
    },
    queryTags(conversationInfo) {
      if (conversationInfo.type === 'C2C' || conversationInfo.type === 'GROUP') {

        const userId = conversationInfo.userProfile.userID.replace("im_", "")

        const queryData = this.$http.adornParams(
          {
            uid: userId,
            virtualUid: this.uid
          }
        )
        // 调接口查询
        this.$http({
          url: this.$http.adornUrl(`/admin/virtual/queryUserTags`),
          method: 'get',
          params: queryData
        }).then(({ data }) => {
          if (data && data.code === 0) {
            console.info("查询用户标签：" + data.result)
            this.userTags = data.result
          } else {
            this.$message.error('获取im状态失败,' + data.msg)
          }
        })
      }
    },
    handleSwitchConversation(conversationID) {
      const conversationInfo = TUIStore.getConversationModel(conversationID)
      if (conversationInfo.type === 'C2C' || conversationInfo.type === 'GROUP') {

        const isGroup = (conversationInfo.type === "GROUP")
        const queryData = this.$http.adornParams(
          {
            virtualUid: this.uid,
            objectId: isGroup ? conversationInfo.groupProfile.groupID : conversationInfo.userProfile.userID,
            type: isGroup ? 1 : 0
          }
        )

        // 调接口查询
        this.$http({
          url: this.$http.adornUrl(`/admin/virtual/getAutoReplyStatus`),
          method: 'get',
          params: queryData
        }).then(({ data }) => {
          if (data && data.code === 0) {
            console.info("是否自动回复：" + data.result)
            this.humanResponse = !data.result
          } else {
            this.$message.error('获取im状态失败,' + data.msg)
            this.humanResponse = false
          }
        })
      } else {
        this.humanResponse = true
      }
      this.queryTags(conversationInfo)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/home.scss";
</style>

